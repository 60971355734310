/* eslint-disable react-hooks/exhaustive-deps */
import FileViewer from "@marcioferlan/react-file-viewer";
import { Select } from "antd";
import axios from "axios";
import React, { memo, useEffect, useState } from "react";
import cookie from "react-cookies";
import CurrencyFormat from "react-currency-format";
import "react-datepicker/dist/react-datepicker.css";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Spinner,
  TabPane,
  Table,
} from "reactstrap";
import constants from "../../Constants";
import PlacesAutocomplete from "../../components/common/PlacesAutocomplete";
import utils from "../../utils";

const { Option } = Select;

const moment = require("moment");
const baseURL = constants.baseURL;
const Authorization = (method) => ({
  method,
  headers: {
    "Content-Type": "application/json",
    authorization: cookie.load("hq-id-qt"),
  },
});
// axios
//   .get("https://media.admin.qiktruck.co.za/SANDTON/20220221_151758.jpg", {
//     headers: {
//       authorization: cookie.load("hq-id-qt"),
//     },
//   })
//   .then((repos) => {
//     console.log("repos", repos);
//   });
// axios
//   .get(
//     "https://media.admin.qiktruck.co.za/Sandton%20Sandtons%20-%20SANDTON/20220221_151758.jpg",
//     {
//       headers: {
//         authorization: cookie.load("hq-id-qt"),
//       },
//     }
//   )
//   .then((repos) => {
//     console.log("repos", repos);
//   });

// axios
//   .get(
//     "https://media.admin.qiktruck.co.za/Sizwe%20Mahlangu%20-%209508045387084/20220314_121623.jpg",
//     {
//       headers: {
//         authorization: cookie.load("hq-id-qt"),
//       },
//     }
//   )
//   .then((repos) => {
//     console.log("repos", repos);
//   });
const southAfricanCities = [
  {
    name: "Johannesburg",
    latitude: -26.2041,
    longitude: 28.0473,
    province: "Gauteng",
  },
  {
    name: "Cape Town",
    latitude: -33.9249,
    longitude: 18.4241,
    province: "Western Cape",
  },
  {
    name: "Durban",
    latitude: -29.8587,
    longitude: 31.0218,
    province: "KwaZulu-Natal",
  },
  {
    name: "Pretoria",
    latitude: -25.7479,
    longitude: 28.2293,
    province: "Gauteng",
  },
  {
    name: "Gqeberha",
    latitude: -33.9608,
    longitude: 25.6022,
    province: "Eastern Cape",
  },
  {
    name: "East London",
    latitude: -33.0153,
    longitude: 27.9113,
    province: "Eastern Cape",
  },
  {
    name: "Bloemfontein",
    latitude: -29.0852,
    longitude: 26.1596,
    province: "Free State",
  },
  {
    name: "Mbombela",
    latitude: -25.4972,
    longitude: 30.9383,
    province: "Mpumalanga",
  },
  {
    name: "Kimberley",
    latitude: -28.7283,
    longitude: 24.7515,
    province: "Northern Cape",
  },
  {
    name: "Polokwane",
    latitude: -23.9043,
    longitude: 29.4689,
    province: "Limpopo",
  },
  {
    name: "Pietermaritzburg",
    latitude: -29.6001,
    longitude: 30.3795,
    province: "KwaZulu-Natal",
  },
  {
    name: "George",
    latitude: -33.9608,
    longitude: 22.4799,
    province: "Western Cape",
  },
  {
    name: "Emalahleni",
    latitude: -25.4333,
    longitude: 29.2,
    province: "Mpumalanga",
  },
  {
    name: "Rustenburg",
    latitude: -25.6667,
    longitude: 27.2667,
    province: "North West",
  },
  {
    name: "Vereeniging",
    latitude: -26.6733,
    longitude: 27.875,
    province: "Gauteng",
  },
  {
    name: "Port Shepstone",
    latitude: -30.6033,
    longitude: 30.3928,
    province: "KwaZulu-Natal",
  },
  {
    name: "Upington",
    latitude: -28.4544,
    longitude: 21.2372,
    province: "Northern Cape",
  },
  {
    name: "Oudtshoorn",
    latitude: -33.5953,
    longitude: 22.2019,
    province: "Western Cape",
  },
  {
    name: "Middelburg",
    latitude: -28.5694,
    longitude: 29.1172,
    province: "Mpumalanga",
  },
  {
    name: "Klerksdorp",
    latitude: -26.88,
    longitude: 26.6872,
    province: "North West",
  },
];

/**
 * Calculates the distance between two geographic coordinates using the Haversine formula.
 * @param {number} lat1 - Latitude of the first point in decimal degrees.
 * @param {number} lon1 - Longitude of the first point in decimal degrees.
 * @param {number} lat2 - Latitude of the second point in decimal degrees.
 * @param {number} lon2 - Longitude of the second point in decimal degrees.
 * @returns {number} - Distance between the two points in kilometers.
 */
function haversineDistance(lat1, lon1, lat2, lon2) {
  const toRadians = (degrees) => degrees * (Math.PI / 180);

  const R = 6371; // Earth's radius in kilometers
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
}

/**
 * Finds the nearest city from the southAfricanCities array based on the provided latitude and longitude.
 * @param {number} targetLat - Latitude of the target location in decimal degrees.
 * @param {number} targetLon - Longitude of the target location in decimal degrees.
 * @returns {Object} - An object containing the nearest city's name, province, and distance in kilometers.
 */
function findNearestCity(targetLat, targetLon) {
  if (typeof targetLat !== "number" || typeof targetLon !== "number") {
    throw new Error("Latitude and Longitude must be numbers.");
  }

  let nearestCity = null;
  let minDistance = Infinity;

  southAfricanCities.forEach((city) => {
    const distance = haversineDistance(
      targetLat,
      targetLon,
      city.latitude,
      city.longitude
    );
    if (distance < minDistance) {
      minDistance = distance;
      nearestCity = city;
    }
  });

  if (nearestCity) {
    return {
      city: nearestCity.name,
      province: nearestCity.province,
      distance_km: parseFloat(minDistance.toFixed(2)),
    };
  } else {
    return null;
  }
}
function MyComponent() {
  const [vPaginator, setVPaginator] = useState(0);
  const [editVehicle, setEditVehicle] = useState(false);
  const [findADriver, setFindADriver] = useState(undefined);
  const [filterDrivers, setFilterDrivers] = useState("");
  const [assignedVehicle, setAssignedVehicle] = useState(undefined);
  const [nearbyVehicles, setNearbyVehicles] = useState([]);
  const [assigningVehicle, setAssigningVehicle] = useState(false);
  const [editDriver, setEditDriver] = useState(false);
  const [editOwner, setEditOwner] = useState(false);
  const [newDriver, setNewDriver] = useState(false);
  const [newOwner, setNewOwner] = useState(false);
  const [editedVehicle, setEditedVehicle] = useState({});
  const [editedDriver, setEditedDriver] = useState({});
  const [editedOwner, setEditedOwner] = useState({});
  const [viewOneFile, setViewOneFile] = useState({});
  const [updatingVehicle, setUpdatingVehicle] = useState(false);
  const [updatingDriver, setUpdatingDriver] = useState(false);
  const [updatingOwner, setUpdatingOwner] = useState(false);
  const [loading, setLoading] = useState(false);
  const [truckType, setTruckType] = useState("");
  const [vehicleStatus, setVehicleStatus] = useState("in-review");
  const [searchText, setSearchText] = useState("");
  const [vehicles, setVehicles] = useState([]);

  const searchVehicles = (truckType, vehicleStatus, searchText) => {
    setLoading(true);
    fetch(
      baseURL +
        "/search-vehicles?text=" +
        searchText +
        "&vehiclestatus=" +
        vehicleStatus +
        "&trucktype=" +
        truckType,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: cookie.load("hq-id-qt"),
        },
      }
    )
      .then((data) => data.json())
      .then((json) => {
        setVehicles([...json.data]);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };
  const fetchInReviewDrivers = () => {
    setLoading(true);
    fetch(
      baseURL +
        "/admin-vehicles?vehiclestatus=in-review&fromdate=" +
        moment().subtract(5, "days").format("YYYY-MM-DD"),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: cookie.load("hq-id-qt"),
        },
      }
    )
      .then((data) => data.json())
      .then((json) => {
        setVehicles([...json]);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const saveVehicleEdits = () => {
    setUpdatingVehicle(true);
    axios
      .post(baseURL + "/update-vehicle", editedVehicle)
      .then((response) => {
        setEditVehicle(false);
      })
      .catch((e) => {
        console.log("/update-vehicle err", e);
        alert(e);
      })
      .finally(() => setUpdatingVehicle(false));
  };
  const updateVehicleStatus = (vehiclestatus) => {
    setUpdatingVehicle(true);
    axios
      .post(
        baseURL + "/update-vehicle-status",
        {
          vehicleid: editedVehicle?.vehicleid,
          vehiclestatus,
        },
        Authorization("POST")
      )
      .then((response) => {
        setEditVehicle(false);
      })
      .catch((e) => {
        console.log("/update-vehicle err", e);
        alert(e);
      })
      .finally(() => setUpdatingVehicle(false));
  };
  const deleteVehicle = (vehicle_) => {
    setUpdatingVehicle(true);
    axios
      .post(
        baseURL + "/delete-vehicle-duplicate",
        vehicle_,
        Authorization("POST")
      )
      .then((response) => {
        setEditVehicle(false);
      })
      .catch((e) => {
        console.log("/delete-vehicle-duplicate err", e);
        alert(e);
      })
      .finally(() => setUpdatingVehicle(false));
  };
  const assignVehicle = () => {
    setAssigningVehicle(true);
    axios
      .post(
        baseURL + "/assign-vehicle",
        {
          orderid: findADriver.orderid,
          vehicleid: assignedVehicle,
        },
        Authorization("POST")
      )
      .then((response) => {
        window.location.reload();
      })
      .catch((e) => {
        console.log("/assign-vehicle err", e);
        alert(e);
        setAssigningVehicle(false);
      });
  };
  const saveDriverEdits = () => {
    setUpdatingDriver(true);
    axios
      .post(
        baseURL + (newDriver ? "/new-driver" : "/update-driver"),
        editedDriver
      )
      .then((response) => {
        setEditDriver(false);
      })
      .catch((e) => {
        console.log((newDriver ? "/new-driver" : "/update-driver") + " err", e);
        alert(e);
      })
      .finally(() => setUpdatingDriver(false));
  };
  const saveOwnerEdits = () => {
    setUpdatingOwner(true);
    axios
      .post(
        baseURL + (newOwner ? "/new-vehicle-owner" : "/update-vehicle-owner"),
        editedOwner
      )
      .then((response) => {
        setEditOwner(false);
      })
      .catch((e) => {
        console.log((newOwner ? "/new-owner" : "/update-owner") + " err", e);
        alert(e);
      })
      .finally(() => setUpdatingOwner(false));
  };
  const updateDriverStatus = (driverstatus) => {
    setUpdatingDriver(true);
    axios
      .post(baseURL + "/update-driver-status", {
        driverid: editedDriver.driverid,
        driverstatus,
      })
      .then((response) => {
        setEditDriver(false);
      })
      .catch((e) => {
        console.log("/update-driver err", e);
        alert(e);
      })
      .finally(() => setUpdatingDriver(false));
  };
  const updateOwnerStatus = (driverstatus) => {
    setUpdatingOwner(true);
    axios
      .post(baseURL + "/update-vehicle-owner-status", {
        driverid: editedOwner.ownerdriverid,
        driverstatus,
      })
      .then((response) => {
        setEditOwner(false);
      })
      .catch((e) => {
        console.log("/update-driver err", e);
        alert(e);
      })
      .finally(() => setUpdatingOwner(false));
  };
  const renderVehicleTracking = (vehicleid) => {
    try {
      let agent = vehicles?.find((f) => f.quoteid === vehicleid)?.tracking
        ?.agent;
      const qtref = vehicles?.find((f) => f.vehicleid === vehicleid)?.tracking
        ?.qtref;
      const prev_ = vehicles?.find((f) => f.vehicleid === vehicleid)?.tracking
        ?.prev;
      let landing = vehicles?.find((f) => f.vehicleid === vehicleid)?.tracking
        ?.landing;
      return (
        landing && (
          <div className="wrap-content">
            <div>
              {landing.replace("https://qiktruck.co.za/", "/").substring(0, 50)}
              <br />
              {prev_.replace("https://qiktruck.co.za/", "/").substring(0, 50)}
              <br />
              {qtref.replace("https://qiktruck.co.za/", "/").substring(0, 50)}
            </div>
            <br />
            <div>{agent}</div>
          </div>
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getImage = (trucktype) => {
    try {
      return `https://media.qiktruck.co.za/${trucktype}.png`;
    } catch (error) {
      return "";
    }
  };

  useEffect(() => {
    let mounted = true;
    fetchInReviewDrivers(mounted);
    return () => {};
  }, []);

  return (
    <div>
      <Modal
        isOpen={typeof findADriver !== "undefined"}
        size={"sm"}
        onOpened={() => {
          const vs = [];
          for (let index = 0; index < vehicles.length; index++) {
            const element = vehicles[index];
            element["distance"] = utils.getDistanceFromLatLonInKm(
              element.homelat,
              element.homelng,
              findADriver.collectionlat,
              findADriver.collectionlng
            );
            element["distancefomdestination"] = utils.getDistanceFromLatLonInKm(
              element.homelat,
              element.homelng,
              findADriver.deliverylat,
              findADriver.deliverylng
            );
            vs.push(element);
          }
          vs.sort((a, b) => (a.distance > b.distance ? 1 : -1));
          setNearbyVehicles(vs);
        }}
      >
        <ModalBody>
          <Row>
            <h3>
              <img src={getImage(findADriver?.trucktype)} alt="" height="60" />
              <CurrencyFormat
                value={findADriver?.sellingprice / 100}
                displayType={"text"}
                thousandSeparator={true}
                prefix={""}
                decimalScale={2}
              />{" "}
              <span>{findADriver?.firstname}</span>
              <span>{findADriver?.lastname}</span>,
              <span>{findADriver?.description}</span>,
              <span>{findADriver?.collectiondate}</span>,
              <span>{findADriver?.collectiontime}</span>
            </h3>
            <br />
            <Col lg={3} sm={12}></Col>
            <Col lg={6} sm={12}>
              <FormGroup>
                <div className="vehicle-finder">
                  <Label for="vehiclesearch">Find a vehicle</Label>
                  <button
                    onClick={() =>
                      setFindADriver({
                        ...findADriver,
                        trucktype: findADriver?.trucktype?.replace(
                          "open",
                          "closed"
                        ),
                      })
                    }
                    className={
                      findADriver?.trucktype?.indexOf("closed") !== -1
                        ? "selected"
                        : ""
                    }
                  >
                    Closed body
                  </button>
                  <button
                    onClick={() =>
                      setFindADriver({
                        ...findADriver,
                        trucktype: findADriver?.trucktype?.replace(
                          "closed",
                          "open"
                        ),
                      })
                    }
                    className={
                      findADriver?.trucktype?.indexOf("open") !== -1
                        ? "selected"
                        : ""
                    }
                  >
                    Open body
                  </button>
                </div>
                <Input
                  id="vehiclesearch"
                  name="vehiclesearch"
                  onChange={(e) => {
                    setFilterDrivers(e.target.value);
                  }}
                />
              </FormGroup>
              <div className="vehicles-picker-list">
                {nearbyVehicles
                  ?.filter((f) => {
                    if (f.trucktype !== findADriver?.trucktype) return false;
                    if (filterDrivers.length < 1) return true;
                    return (
                      f.firstname
                        .toLowerCase()
                        .indexOf(filterDrivers.toLowerCase()) > -1 ||
                      f.lastname
                        .toLowerCase()
                        .indexOf(filterDrivers.toLowerCase()) > -1 ||
                      f.licensenumber
                        .toLowerCase()
                        .indexOf(filterDrivers.toLowerCase()) > -1
                    );
                  })
                  ?.map((v) => (
                    <button
                      className={
                        "find-a-vehicle " +
                        (v.vehicleid === assignedVehicle ? "selected" : "") +
                        (v.vehicleid === findADriver.vehicleid
                          ? " active"
                          : " ") +
                        " " +
                        v.vehiclestatus
                      }
                      onClick={() => setAssignedVehicle(v.vehicleid)}
                    >
                      <img src={getImage(v?.trucktype)} alt="" height="30" />
                      {v.firstname} {v.lastname} - {v.licensenumber},{" "}
                      {Math.floor(v.distance)}km {" or "}
                      {Math.floor(v.distancefomdestination)}km
                    </button>
                  ))}
              </div>
            </Col>
            <Col lg={3} sm={12}></Col>
            <br />
            <br />
            <Col lg={3} sm={12}>
              <Button
                color="primary"
                onClick={() => {
                  if (assignedVehicle?.length > 0) assignVehicle();
                  else setFindADriver(undefined);
                }}
                disabled={assigningVehicle}
              >
                Assign vehicle
              </Button>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setFindADriver(undefined)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={editVehicle}
        onClosed={() => setViewOneFile({})}
        size={"lg"}
        onOpened={() => {
          axios
            .post(
              baseURL + "/vehicle-documents",
              {
                driverprofile: (
                  editedVehicle.firstname +
                  " " +
                  editedVehicle.lastname +
                  " - " +
                  editedVehicle.idnumber
                )
                  .replace(/ {2}/g, " ")
                  .trim(),
              },
              Authorization("POST")
            )
            .then((repos) => {
              const vehicleDocuments = repos.data;
              setEditedVehicle({
                ...editedVehicle,
                documents: vehicleDocuments,
              });
            });
        }}
      >
        <ModalBody>
          <h3>
            <img src={getImage(editedVehicle?.trucktype)} alt="" height="60" />
            <span>{editedVehicle.packagetype}</span>
            <span>{editedVehicle?.vehicleid}</span>
            <p>
              {vehicles
                .filter((v) => v.licensenumber === editedVehicle.licensenumber)
                .map((v) => {
                  return (
                    <button
                      key={v.vehicleid}
                      onClick={() => {
                        if (v.vehiclestatus !== "available")
                          deleteVehicle({
                            ...v,
                          });
                      }}
                    >
                      {v.licensenumber}
                      <i
                        className={
                          "mdi mdi-delete " +
                          (v.vehiclestatus !== "available" ? "red" : "")
                        }
                      >
                        DELETE ONLY WHEN DUPLICATE
                      </i>
                      <span>
                        {moment(v.dateadded).format(
                          "ddd D MMM YYYY hh:mm:ss a"
                        )}
                      </span>
                    </button>
                  );
                })}
            </p>
          </h3>
          {viewOneFile.filePath && (
            <FileViewer
              headers={{
                authorization: cookie.load("hq-id-qt"),
              }}
              fileType={viewOneFile.fileType}
              filePath={viewOneFile.filePath}
              loaderComponent={(e) => {
                console.log("loaderComponent", e);
                setViewOneFile({
                  fileType: null,
                  filePath: null,
                });
              }}
              errorComponent={(e) => {
                console.log("errorComponent", e);
                setViewOneFile({
                  fileType: null,
                  filePath: null,
                });
              }}
              onError={(e) => {
                console.log("onError", e);
                setViewOneFile({
                  fileType: null,
                  filePath: null,
                });
              }}
            />
          )}
          <div className="file-preview-parent">
            {editedVehicle?.documents?.map((m) => (
              <>
                <a
                  href={"https://media.admin.qiktruck.co.za/" + m}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="file-preview"
                >
                  <img
                    src={"https://media.admin.qiktruck.co.za/" + m}
                    alt="doc"
                  />
                  <span>{m?.split("/")[1]}</span>
                </a>
              </>
            ))}
          </div>
          <hr />
          <div>
            <Button
              color={
                editedVehicle.vehiclestatus === "in-review"
                  ? "primary"
                  : "secondary"
              }
              outline
              onClick={() => updateVehicleStatus("in-review")}
            >
              In review
            </Button>{" "}
            <Button
              outline
              color={
                editedVehicle.vehiclestatus === "available"
                  ? "success"
                  : "secondary"
              }
              onClick={() => updateVehicleStatus("available")}
            >
              Available
            </Button>{" "}
            <Button
              color={
                editedVehicle.vehiclestatus === "resting"
                  ? "warning"
                  : "secondary"
              }
              outline
              onClick={() => updateVehicleStatus("resting")}
            >
              Resting
            </Button>{" "}
            <Button
              color={
                editedVehicle.vehiclestatus === "blocked"
                  ? "danger"
                  : "secondary"
              }
              outline
              onClick={() => updateVehicleStatus("blocked")}
            >
              Blocked
            </Button>
            <Button
              color={
                editedVehicle.vehiclestatus === "processing"
                  ? "danger"
                  : "secondary"
              }
              outline
              onClick={() => updateVehicleStatus("processing")}
            >
              Processing
            </Button>
          </div>
          <br />
          <Row>
            <Col lg={6} sm={6}>
              <FormGroup>
                <Label for="licensenumber">Truck License Plate Number </Label>
                <Input
                  id="licensenumber"
                  name="licensenumber"
                  defaultValue={
                    editedVehicle.licensenumber ||
                    editedVehicle?.vehicleid?.split("-")[0]
                  }
                  onChange={(e) => {
                    setEditedVehicle({
                      ...editedVehicle,
                      licenseplatenumber: e.target.value,
                      licensenumber: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg={6} sm={6}>
              <FormGroup>
                <Label for="vin">Truck VIN </Label>

                <Input
                  id="vin"
                  name="vin"
                  defaultValue={
                    editedVehicle.vin || editedVehicle?.vehicleid?.split("-")[0]
                  }
                  onChange={(e) => {
                    setEditedVehicle({
                      ...editedVehicle,
                      vin: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col lg={6} sm={6}>
              <FormGroup>
                <Label for="enginenumber">Engine Number </Label>
                <Input
                  id="enginenumber"
                  name="enginenumber"
                  defaultValue={
                    editedVehicle.enginenumber ||
                    editedVehicle?.vehicleid?.split("-")[0]
                  }
                  onChange={(e) => {
                    setEditedVehicle({
                      ...editedVehicle,
                      enginenumber: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg={6} sm={6}>
              <FormGroup>
                <Label for="driverid">Driver ID </Label>
                <Input
                  id="driverid"
                  name="driverid"
                  defaultValue={
                    editedVehicle.driverid ||
                    editedVehicle?.vehicleid?.split("-")[0]
                  }
                  onChange={(e) => {
                    setEditedVehicle({
                      ...editedVehicle,
                      driverid: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6}>
              <FormGroup>
                <Label for="make">Make </Label>
                <Input
                  id="make"
                  name="make"
                  defaultValue={
                    editedVehicle.make ||
                    editedVehicle?.vehicleid?.split("-")[0]
                  }
                  onChange={(e) => {
                    setEditedVehicle({
                      ...editedVehicle,
                      make: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg={6} sm={6}>
              <FormGroup>
                <Label for="model">Model</Label>
                <Input
                  id="model"
                  name="model"
                  defaultValue={
                    editedVehicle.model ||
                    editedVehicle?.vehicleid?.split("-")[0]
                  }
                  onChange={(e) => {
                    setEditedVehicle({
                      ...editedVehicle,
                      model: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6}>
              <FormGroup>
                <Label for="yearcreated">Year created</Label>
                <Input
                  id="yearcreated"
                  name="yearcreated"
                  defaultValue={
                    editedVehicle.yearcreated ||
                    editedVehicle?.vehicleid?.split("-")[0]
                  }
                  onChange={(e) => {
                    setEditedVehicle({
                      ...editedVehicle,
                      yearcreated: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg={6} sm={6}>
              <FormGroup>
                <Label for="loadcovertype">Cover type</Label>
                <Input
                  id="loadcovertype"
                  name="loadcovertype"
                  defaultValue={
                    editedVehicle.loadcovertype ||
                    editedVehicle?.vehicleid?.split("-")[0]
                  }
                  onChange={(e) => {
                    setEditedVehicle({
                      ...editedVehicle,
                      loadcovertype: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6}>
              <FormGroup className="mb-4">
                <Label className="font-15">Truck Location*</Label>
                <PlacesAutocomplete
                  type="text"
                  className="form-control"
                  id="location"
                  required
                  onPlaceSelected={(place) => {
                    setEditedVehicle({
                      ...editedVehicle,
                      homelat: place?.geometry?.location?.lat(),
                      homelng: place?.geometry?.location?.lng(),
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg={6} sm={6}>
              <FormGroup>
                <Label for="trucktype">Vehicle type</Label>
                <Input
                  id="trucktype"
                  name="trucktype"
                  defaultValue={
                    editedVehicle.trucktype ||
                    editedVehicle?.vehicleid?.split("-")[0]
                  }
                  onChange={(e) => {
                    setEditedVehicle({
                      ...editedVehicle,
                      trucktype: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Button
            color="primary"
            onClick={() => {
              saveVehicleEdits();
            }}
            disabled={updatingVehicle}
          >
            Save
          </Button>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setEditVehicle(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={editDriver}
        size={"lg"}
        onOpened={() => {
          axios
            .post(
              baseURL + "/vehicle-documents",
              {
                driverprofile: (
                  editedDriver.firstname +
                  " " +
                  editedDriver.lastname +
                  " - " +
                  editedDriver.idnumber
                )
                  .replace(/ {2}/g, " ")
                  .trim(),
              },
              Authorization("POST")
            )
            .then((repos) => {
              const vehicleDocuments = repos.data;
              setEditedDriver({
                ...editedDriver,
                documents: vehicleDocuments,
              });
            });
        }}
      >
        <ModalBody>
          <h3>Driver</h3>
          <p>{editedDriver.driverid}</p>
          <div className="file-preview-parent">
            {editedDriver?.documents?.map((m) => (
              <>
                <a
                  href={"https://media.admin.qiktruck.co.za/" + m}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="file-preview"
                >
                  <img
                    src={"https://media.admin.qiktruck.co.za/" + m}
                    alt="doc"
                  />
                  <span>{m?.split("/")[1]}</span>
                </a>
              </>
            ))}
          </div>
          <hr />
          <div>
            <Button
              color={
                editedDriver.driverstatus === "in-review"
                  ? "primary"
                  : "secondary"
              }
              outline
              onClick={() => updateDriverStatus("in-review")}
            >
              In review
            </Button>{" "}
            <Button
              outline
              color={
                editedDriver.driverstatus === "available"
                  ? "success"
                  : "secondary"
              }
              onClick={() => updateDriverStatus("available")}
            >
              Available
            </Button>{" "}
            <Button
              color={
                editedDriver.driverstatus === "resting"
                  ? "warning"
                  : "secondary"
              }
              outline
              onClick={() => updateDriverStatus("resting")}
            >
              Resting
            </Button>{" "}
            <Button
              color={
                editedDriver.driverstatus === "blocked" ? "danger" : "secondary"
              }
              outline
              onClick={() => updateDriverStatus("blocked")}
            >
              Blocked
            </Button>
          </div>
          <br />
          <Row>
            <Col lg={6} sm={6}>
              <FormGroup>
                <Label for="firstname">Driver Firstname</Label>
                <Input
                  id="firstname"
                  name="firstname"
                  type="text"
                  defaultValue={editedDriver?.firstname}
                  onChange={(e) => {
                    setEditedDriver({
                      ...editedDriver,
                      firstname: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg={6} sm={6}>
              <FormGroup>
                <Label for="lastname">Surname</Label>
                <Input
                  id="lastname"
                  name="lastname"
                  type="text"
                  defaultValue={editedDriver.lastname}
                  onChange={(e) => {
                    setEditedDriver({
                      ...editedDriver,
                      lastname: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6}>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  defaultValue={editedDriver.email}
                  onChange={(e) => {
                    setEditedDriver({
                      ...editedDriver,
                      email: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg={6} sm={6}>
              <FormGroup>
                <Label for="mobilenumber">Mobile number</Label>
                <Input
                  id="mobilenumber"
                  name="mobilenumber"
                  type="tel"
                  defaultValue={editedDriver.mobilenumber}
                  onChange={(e) => {
                    setEditedDriver({
                      ...editedDriver,
                      mobilenumber: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6}>
              <FormGroup>
                <Label for="email">ID/Passport number</Label>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  defaultValue={editedDriver.idnumber}
                  onChange={(e) => {
                    setEditedDriver({
                      ...editedDriver,
                      idnumber: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg={6} sm={6}>
              <FormGroup>
                <Label for="email">License number</Label>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  defaultValue={editedDriver.driverlicense}
                  onChange={(e) => {
                    setEditedDriver({
                      ...editedDriver,
                      driverlicense: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6}>
              <FormGroup className="mb-4">
                <Label className="font-15">Driver Location*</Label>
                <PlacesAutocomplete
                  type="text"
                  className="form-control"
                  id="location"
                  required
                  onPlaceSelected={(place) => {
                    setEditedDriver({
                      ...editedDriver,
                      driverhomelat: place?.geometry?.location?.lat(),
                      driverhomelng: place?.geometry?.location?.lng(),
                    });
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Button
            color="primary"
            onClick={() => {
              saveDriverEdits();
            }}
            disabled={updatingDriver}
          >
            Save
          </Button>
          <FormGroup check inline style={{ marginLeft: 20 }}>
            <Input
              type="checkbox"
              onChange={(e) => setNewDriver(e.target.checked)}
            />
            <Label check>New Driver</Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setEditDriver(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={editOwner} size={"lg"}>
        <ModalBody>
          <h3>Owner</h3>
          <p>{editedOwner.ownerdriverid}</p>
          <div>
            <Button
              color={
                editedOwner.driverstatus === "in-review"
                  ? "primary"
                  : "secondary"
              }
              outline
              onClick={() => updateOwnerStatus("in-review")}
            >
              In review
            </Button>{" "}
            <Button
              outline
              color={
                editedOwner.driverstatus === "available"
                  ? "success"
                  : "secondary"
              }
              onClick={() => updateOwnerStatus("available")}
            >
              Available
            </Button>{" "}
            <Button
              color={
                editedOwner.driverstatus === "resting" ? "warning" : "secondary"
              }
              outline
              onClick={() => updateOwnerStatus("resting")}
            >
              Resting
            </Button>{" "}
            <Button
              color={
                editedOwner.driverstatus === "blocked" ? "danger" : "secondary"
              }
              outline
              onClick={() => updateOwnerStatus("blocked")}
            >
              Blocked
            </Button>
          </div>
          <br />
          <Row>
            <Col lg={6} sm={6}>
              <FormGroup>
                <Label for="firstname">Owner Firstname</Label>
                <Input
                  id="firstname"
                  name="firstname"
                  type="text"
                  defaultValue={editedOwner?.firstname}
                  onChange={(e) => {
                    setEditedOwner({
                      ...editedOwner,
                      firstname: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg={6} sm={6}>
              <FormGroup>
                <Label for="lastname">Surname</Label>
                <Input
                  id="lastname"
                  name="lastname"
                  type="text"
                  defaultValue={editedOwner?.lastname}
                  onChange={(e) => {
                    setEditedOwner({
                      ...editedOwner,
                      lastname: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6}>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  defaultValue={editedOwner.email}
                  onChange={(e) => {
                    setEditedOwner({
                      ...editedOwner,
                      email: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg={6} sm={6}>
              <FormGroup>
                <Label for="mobilenumber">Mobile number</Label>
                <Input
                  id="mobilenumber"
                  name="mobilenumber"
                  type="tel"
                  defaultValue={editedOwner.mobilenumber}
                  onChange={(e) => {
                    setEditedOwner({
                      ...editedOwner,
                      mobilenumber: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6}>
              <FormGroup>
                <Label for="email">ID/Passport number</Label>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  defaultValue={editedOwner.idnumber}
                  onChange={(e) => {
                    setEditedOwner({
                      ...editedOwner,
                      idnumber: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg={6} sm={6}>
              <FormGroup>
                <Label for="email">License number</Label>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  defaultValue={editedOwner.driverlicense}
                  onChange={(e) => {
                    setEditedOwner({
                      ...editedOwner,
                      driverlicense: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6}>
              <FormGroup className="mb-4">
                <Label className="font-15">Owner Location*</Label>
                <PlacesAutocomplete
                  type="text"
                  className="form-control"
                  id="location"
                  required
                  onPlaceSelected={(place) => {
                    setEditedOwner({
                      ...editedOwner,
                      driverhomelat: place?.geometry?.location?.lat(),
                      driverhomelng: place?.geometry?.location?.lng(),
                    });
                  }}
                />
              </FormGroup>
            </Col>
          </Row>

          <Button
            color="primary"
            onClick={() => {
              saveOwnerEdits();
            }}
            disabled={updatingOwner}
          >
            Save
          </Button>
          <FormGroup check inline style={{ marginLeft: 20 }}>
            <Input
              type="checkbox"
              onChange={(e) => setNewOwner(e.target.checked)}
            />
            <Label check>New Owner</Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setEditOwner(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <TabPane>
        <div className="main-filter">
          <Input
            className="vehiclesearch"
            name="vehiclesearch"
            placeholder="Search vehicles and drivers"
            onChange={(e) => {
              if (e.target.value.length > 3 || e.target.value.length < 1) {
                setSearchText(e.target.value);
                searchVehicles(truckType, vehicleStatus, e.target.value);
              }
            }}
          />
          <Select
            className="vehiclestatus main-select"
            placeholder="Select Vehicle Status"
            onChange={(e) => {
              setVehicleStatus(e);
              searchVehicles(truckType, e, searchText);
            }}
            value={vehicleStatus}
            style={{ width: "100%" }}
          >
            {["available", "in-review", "resting", "blocked", "processing"].map(
              (type) => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              )
            )}
          </Select>
          <Select
            className="vehiclesearch main-select"
            placeholder="Select Truck Type"
            onChange={(e) => {
              setTruckType(e);
              searchVehicles(e, vehicleStatus, searchText);
            }}
            value={truckType || "Select Truck Type"}
            style={{ width: "100%" }}
          >
            {[
              "motorbike",
              "minivanopen",
              "minivanclosed",
              "1tonopen",
              "1tonclosed",
              "1.5tonopen",
              "1.5tonclosed",
              "4tonopen",
              "4tonclosed",
              "4tontipperandtrailer",
              "8tonopen",
              "8tonclosed",
              "8tontipperandtrailer",
              "10tonopen",
              "10tonclosed",
              "12tonopen",
              "12tonclosed",
              "14tonopen",
              "14tonclosed",
              "16tonopen",
              "16tonclosed",
              "20tonopen",
              "20tonclosed",
              "34tonclosed",
              "34tonrollbackflatbed",
            ].map((type) => (
              <Option key={type} value={type}>
                {type}
              </Option>
            ))}
          </Select>
        </div>
        <Pagination aria-label="Page navigation example">
          {vehicles.length > 0 &&
            new Array(Math.ceil(vehicles.length / 30))
              .fill("")
              .map((e, index) => {
                return (
                  <PaginationItem
                    key={index + Date.now()}
                    active={index === vPaginator}
                    onClick={() => {
                      setVPaginator(index);
                    }}
                  >
                    <PaginationLink>{index}</PaginationLink>
                  </PaginationItem>
                );
              })}
        </Pagination>

        <Table striped>
          <thead>
            <tr>
              <th>
                {vPaginator * 30} to {vPaginator * 30 + 30} of {vehicles.length}
              </th>
              <th>Vehicle</th>
              <th>Driver</th>
              <th>Owner</th>
              <th>Location</th>
            </tr>
          </thead>
          {loading && <Spinner />}
          <tbody>
            {vehicles.length > 0 &&
              vehicles
                .sort((a, b) => moment(b.dateadded) - moment(a.dateadded))
                .slice(vPaginator * 30, vPaginator * 30 + 30)
                .map((vehicle, ps) => {
                  const image = getImage(vehicle.trucktype);

                  return (
                    <tr key={vehicle.vehicleid}>
                      <td className="vehiclestatus-parent">
                        <a
                          href={"https://qiktruck.co.za?v=" + vehicle.vehicleid}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={image} alt="" height="30" />
                        </a>
                        <span
                          className={"vehiclestatus " + vehicle.vehiclestatus}
                        >
                          {vehicle.vehiclestatus}
                        </span>
                        {" - "}
                        {moment(vehicle.dateadded).fromNow()}
                      </td>
                      <td className="pointed-at">
                        {/* Licenses */}
                        <button
                          onClick={() => {
                            console.log(vehicle);
                            setEditedVehicle({ ...vehicle });
                            setEditVehicle(true);
                          }}
                        >
                          {vehicle.licensenumber?.toUpperCase()}
                        </button>
                        <br />
                        <span style={{ fontSize: 12 }}>
                          {renderVehicleTracking(vehicle.vehicleid)}
                        </span>
                      </td>
                      <td className="pointed-at">
                        <b>
                          {vehicles.filter(
                            (v) => v.licensenumber === vehicle.licensenumber
                          ).length > 1 && (
                            <i className="mdi mdi-account-multiple red">
                              {
                                vehicles.filter(
                                  (v) =>
                                    v.licensenumber === vehicle.licensenumber
                                ).length
                              }
                            </i>
                          )}
                        </b>
                        {vehicle.firstname} {vehicle.lastname}
                        <button
                          onClick={() => {
                            setEditedDriver(vehicle);
                            setEditDriver(true);
                          }}
                          className="mdi mdi-account-edit ml-3"
                        ></button>
                        <span className={vehicle.driverstatus}>
                          {vehicle.driverstatus}
                        </span>
                      </td>
                      <td className="pointed-at ">
                        {vehicle.ownerfirstname} {vehicle.ownerlastname} -{" "}
                        {vehicle.owneridnumber}
                        <button
                          onClick={() => {
                            setEditedOwner({
                              firstname: vehicle.ownerfirstname,
                              lastname: vehicle.ownerlastname,
                              idnumber: vehicle.owneridnumber,
                              email: vehicle.owneremail,
                              mobilenumber: vehicle.ownermobilenumber,
                              driverhomelat: vehicle.ownerhomelat,
                              driverhomelng: vehicle.ownerhomelng,
                              ownerdriverid: vehicle.ownerdriverid,
                              driverstatus: vehicle.ownerdriverstatus,
                              vehicleid: vehicle.vehicleid,
                              driverid: vehicle.ownerdriverid,
                              driverlicense: vehicle.ownerdriverlicense,
                            });
                            setEditOwner(true);
                          }}
                          className="mdi mdi-account-edit ml-3"
                        ></button>
                        <span className={vehicle.ownerdriverstatus}>
                          {vehicle.ownerdriverstatus}
                        </span>
                      </td>
                      <td>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            "https://maps.google.com/?q=" +
                            vehicle.homelat +
                            "," +
                            vehicle.homelng
                          }
                        >
                          {
                            findNearestCity(vehicle.homelat, vehicle.homelng)
                              .city
                          }
                          ,{" "}
                          {
                            findNearestCity(vehicle.homelat, vehicle.homelng)
                              .province
                          }
                          ,{" "}
                          {
                            findNearestCity(vehicle.homelat, vehicle.homelng)
                              .distance_km
                          }
                          km
                        </a>
                        {moment(vehicle.dateadded).format(
                          "ddd D MMM YYYY hh:mm:ss a"
                        )}
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </Table>
      </TabPane>
      <PlacesAutocomplete style={{ display: "none" }} />
    </div>
  );
}
export default memo(MyComponent);
